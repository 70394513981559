import * as React from 'react'
import { Link } from 'gatsby'
import {
    Box,
    Text,
    Flex,
    Heading,
    Wrap,
    Center,
    Spacer,
} from '@chakra-ui/react'

// markup
const NotFoundPage = () => {
    return (
        <Flex align="center" justify="center" height="100vh">
            <Box mx={'auto'}>
                <Heading>404: Not Found</Heading>
            </Box>
            <Box mx={'auto'}>
                <main>
                    <title>Not found</title>
                    <h1>Page not found</h1>
                    <p>
                        Sorry{' '}
                        <span role="img" aria-label="Pensive emoji">
                            😔
                        </span>{' '}
                        we couldn’t find what you were looking for.
                        <br />
                        {process.env.NODE_ENV === 'development' ? (
                            <>
                                <br />
                                Try creating a page in <code>src/pages/</code>.
                                <br />
                            </>
                        ) : null}
                        <br />
                        <Link to="/">Go home</Link>.
                    </p>
                </main>
            </Box>
        </Flex>
    )
}

export default NotFoundPage
